import React, { useState } from "react";
import PortableText from "react-portable-text";

const Description = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function handleChangeOption(index) {
    setSelectedIndex(index);
  }

  return (
    <div className="relative block box-border pb-10 show">
      <div className="relative max-w-[1140px] w-full mx-auto">
        <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5 min-h-[90vh]">
          <div className="block box-border">
            <div className="block w-full p-[10px] sm:p-5">
              {/* Buttons */}
              <div className="block w-full mx-auto text-center">
                {data?.map((item, index) => (
                  <button
                    key={index}
                    className={`inline-block py-[2px] px-[10px] m-[6px] text-sm sm:text-base uppercase rounded-[5px] text-center no-underline 
                whitespace-nowrap border border-solid border-[#ccc]
                box-border transition-all duration-200 ease-linear tracking-[1px] ${
                  selectedIndex === index
                    ? "bg-[#006600] text-white"
                    : "bg-[#cccccc] hover:text-[#006600]"
                } `}
                    onClick={() => handleChangeOption(index)}
                    aria-label={item.btnText}
                  >
                    {item.btnText}
                  </button>
                ))}
              </div>
              {/* Container */}
              {data?.map((item, index) => {
                if (selectedIndex === index) {
                  return (
                    <div className="relative block p-3 g-scaleIn">
                      <div className="flex flex-col flex-wrap justify-center p-[10px] sm:p-5 min-h-[50vh] sm:min-h-[70vh]">
                        <div className="block w-3/4 mx-auto py-5">
                          <p className="text-2xl sm:text-3xl text-[#323A44] font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
                            {item.heading}
                          </p>
                        </div>
                        <div className="p-[10px] text-left text-[#323A44] font-bold">
                          <PortableText
                            content={item?._rawContent}
                            className=" text-[#323A44] text-sm sm:text-base  leading-8 font-semibold  block-content max-w-none"
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
