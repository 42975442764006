import React from "react";

export default function CompaniesGuided({ data, show = "show" }) {
  return (
    <div
      className={`relative p-5  z-[1] ${show}`}
      style={{
        backgroundImage: `linear-gradient(
      to bottom,
      rgba(192, 192, 192, 0.9),
      rgba(121, 121, 121, 0.9)
    ),
    url(${data?.sectionBg?.asset?.url})`,
        backgroundSize: "initial",
      }}
    >
      <div className="relative block mx-auto pb-[100px]">
        <p className=" text-[30px] sm:text-[50px] tracking-[2px] leading-[63px] text-white font-bold text-center py-5 mb-3">
          {data?.heading}
        </p>
        <br />
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-1 gap-y-10 ">
          {data?.list.map((item, index) => {
            return (
              <div
                key={index}
                className="text-sm sm:text-base text-white leading-[26px] font-semibold uppercase p-[10px] mb-5 text-center"
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute block overflow-visible z-[2] -bottom-2 pointer-events-auto w-full h-auto">
        <div className="relative block mx-auto overflow-visible box-border -ml-5  text-center pointer-events-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height="60"
            preserveAspectRatio="none"
            viewBox="0 0 1695 60"
            className="max-w-full w-[9999px] transition-transform duration-200 ease-out h-[60px] overflow-visible text-center"
          >
            <g>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 1695 52"
              >
                <path
                  fill="#FFF"
                  d="M1695 52H0V13.095C135.4 24.405 289.6 30.06 462.5 30.06 721.9 30.06 936.7.001 1212.2.001c183.6-.06 344.5 4.345 482.8 13.095V52z"
                ></path>
              </svg>
              <path
                fill="#FFF"
                d="M0 19.643c135.4 16.964 289.6 25.446 462.5 25.446 259.4 0 474.2-45.088 749.7-45.088 183.6-.089 344.5 6.518 482.8 19.642V60H0V19.643z"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}
